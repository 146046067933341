var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.id},[(_vm.container && _vm.container.useContainer)?_c('div',{staticClass:"container custom-component-container justify-content-md-center"},[_c('div',{class:{
                'row': true,
                'justify-content-center': _vm.container.center
            }},[_c("style",{tag:"component"},[_vm._v(_vm._s(_vm.renderedStyles))]),_vm._v(" "),_c('div',{class:{ 'justify-content-center': _vm.container.center, 'col': true },style:({
                    maxWidth: _vm.container.containerMaxWidth,
                    maxHeight: _vm.container.containerMaxHeight,
                    overflow: 'hidden'
                }),domProps:{"innerHTML":_vm._s(_vm.renderedTemplate)}})],1)]):_c('div',{staticClass:"custom-component-container"},[_c("style",{tag:"component"},[_vm._v(_vm._s(_vm.renderedStyles))]),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(_vm.renderedTemplate)}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }